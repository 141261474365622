<!--
 * @Description: 文件描述
-->
<template>
  <a-card class="data_list_card" title="检测数据">
    <div class="data_list_wrapper">
      <div class="data_list">
        <!-- 热成像 -->
        <template v-if="info.thermalImageCount">
          <DataCardItem
            title="热成像图像"
            :count="info.thermalImageCount"
            :treeData="[info.thermalImage]"
          ></DataCardItem>
          <div class="divide_line"></div>
        </template>

        <!-- 可见光 -->
        <template v-if="info.visibleLightCount">
          <DataCardItem
            title="可见光图像"
            :count="info.visibleLightCount"
            :treeData="[info.visibleLight]"
          ></DataCardItem>
          <div class="divide_line"></div>
        </template>

        <!-- 敲击 -->
        <template v-if="info.knockLineCount">
          <DataCardItem title="敲击数据" :count="info.knockLineCount" :treeData="[info.knockLine]"></DataCardItem>
          <div class="divide_line"></div>
        </template>

        <!-- 雷达 -->
        <template v-if="info.radarPointCount">
          <DataCardItem title="雷达数据" :count="info.radarPointCount" :treeData="[info.radarPoint]"></DataCardItem>
          <div class="divide_line"></div>
        </template>
      </div>

      <slot></slot>
    </div>
  </a-card>
</template>

<script>
import DataCardItem from './DataCardItem.vue'

export default {
  components: {
    DataCardItem
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.data_list_wrapper {
  max-height: 900px;
  overflow: auto;
}

.data_list {
  overflow: auto;
  display: flex;
  justify-content: space-around;

  .divide_line {
    margin: 0 40px;
    border-right: 1px solid #eee;
    width: 1px;
    align-self: stretch;

    &:last-of-type {
      display: none;
    }
  }
}
</style>
