<!--
 * @Description: 单个数据列表
-->

<template>
  <a-card class="data_card_item" :title="title">
    <span slot="extra">共{{ count }}个</span>
    <div class="content">
      <a-tree :tree-data="treeData" defaultExpandAll>
        <template #title="{ name, red }">
          <a-badge :dot="red">
            <span>{{ name }}</span>
          </a-badge>
        </template>
      </a-tree>
    </div>
  </a-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '可见光数据'
    },
    count: {
      type: Number,
      default: 0
    },
    treeData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.data_card_item {
  flex: 1;
  height: 100%;

  // 重设标题圆角
  &.ant-card {
    border-radius: 4px !important;
  }

  .content {
    padding: 5px 20px;
  }
}
</style>
