var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "data_card_item", attrs: { title: _vm.title } },
    [
      _c("span", { attrs: { slot: "extra" }, slot: "extra" }, [
        _vm._v("共" + _vm._s(_vm.count) + "个"),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("a-tree", {
            attrs: { "tree-data": _vm.treeData, defaultExpandAll: "" },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function (ref) {
                  var name = ref.name
                  var red = ref.red
                  return [
                    _c("a-badge", { attrs: { dot: red } }, [
                      _c("span", [_vm._v(_vm._s(name))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }